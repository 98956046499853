import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import green from '@mui/material/colors/green';
import deepOrange from '@mui/material/colors/deepOrange';

export default createTheme({
	palette: {
		mode: 'dark',
		default: {
			main: grey[300],
			dark: grey[400]
		},
		textSecondary: {
			main: grey[300],
			dark: grey[400]
		},
		primary: {
			main: deepOrange[500]
		},
		secondary: {
			main: green[500]
		},
		background: {
			default: '#111'
		},
		action: {
			active: green[500],
			selected: '#4caf5033',
			hover: '#4caf5088'
		}
	}
});
