import React, { useEffect, useRef } from 'react';

import {
	withHandlers,
	compose,
	lifecycle,
	renderComponent,
	branch
} from 'recompose';
import { isBefore } from 'date-fns/fp';

import { withStateCreators } from '../../lib/utils/hoc';
import styles from '../css/WelcomePopup.module.scss';
import { either } from '../../lib/utils/utils';
import withStyles from '@mui/styles/withStyles';
import { postGetServerVar } from '../../postGetStatic';
import cookie from 'react-cookies';
import CenterProgress from '../../CenterProgress';
import ButtonLeftIcon from '../../controls/components/ButtonLeftIcon';
import ajs from 'animejs';
const MAX_LIFE = 2147483647;
const POPUP_VER_STR = 'hasPopUp';
const Nothing = () => <></>;

const enhance = compose(
	withStateCreators(
		{
			updateOpen: () => open => ({ open })
		},
		() => ({
			open: true
		})
	),
	lifecycle({
		componentDidMount() {
			postGetServerVar(POPUP_VER_STR).then(
				either(
					console.log,
					({
						hasPopUp,
						welcomePopupTitle,
						welcomePopupText,
						welcomePopupDate
					}) => {
						const val = cookie.load(POPUP_VER_STR);
						if (hasPopUp && val !== hasPopUp) {
							this.setState({
								hasPopUp,
								welcomePopupTitle,
								welcomePopupText,
								welcomePopupDate
							});
						} else {
							this.setState({
								hasPopUp: false,
								welcomePopupTitle: '',
								welcomePopupText: '',
								welcomePopupDate: ''
							});
						}
					}
				)
			);
		}
	}),
	withHandlers({
		onClose:
			({ updateOpen, hasPopUp }) =>
			() => {
				cookie.save(POPUP_VER_STR, hasPopUp, {
					maxAge: MAX_LIFE,
					path: '/'
				});
				updateOpen(false);
			}
	}),
	branch(
		({ hasPopUp }) => hasPopUp === undefined,
		renderComponent(CenterProgress)
	),
	branch(
		({ open, hasPopUp, welcomePopupDate }) =>
			hasPopUp === false ||
			!open ||
			!isBefore(new Date(welcomePopupDate), new Date()),
		renderComponent(Nothing)
	)
);

const styles2 = () => ({});

const WelcomePopup = enhance(
	({ onClose, open, welcomePopupTitle, welcomePopupText }) => {
		const noticeRef = useRef(null);
		const welcomePopupTextRef = useRef(null);
		const cdTeamRef = useRef(null);

		useEffect(() => {
			if (!welcomePopupTextRef.current || !cdTeamRef.current) {
				return;
			}
			const e = welcomePopupTextRef.current;
			const textWidth = e.clientWidth;

			const animation = ajs({
				targets: [noticeRef.current, welcomePopupTextRef.current],
				translateY: [textWidth, 0],
				duration: 350,
				delay: ajs.stagger(100),
				easing: 'spring(1, 40, 10, 0)'
			});

			animation.finished.then(v => {
				ajs({
					targets: cdTeamRef.current,
					rotate: ['60deg', '-4deg'],
					translateY: 3,
					scale: [2, 1],
					delay: 400,
					duration: 2200,
					easing: 'spring(1, 60, 10, 0)'
				});
				console.log(
					'elRef2.current.children',
					cdTeamRef.current.children
				);

				ajs({
					targets: cdTeamRef.current.children,
					opacity: [0, 1],
					easing: 'easeInOutQuad',
					scale: [4, 1],

					duration: 400,
					delay: ajs.stagger(100, { from: 'center' })
				});
			});
		}, [open]);

		return (
			<div className={styles.overlay}>
				<div className={styles.wrap}>
					<div className={styles.mainText}>
						<div ref={noticeRef} className={styles.title}>
							{welcomePopupTitle}
						</div>
						<span ref={welcomePopupTextRef}>
							{welcomePopupText}
						</span>
						<span ref={cdTeamRef} className={styles.cyberdevTeam}>
							<span>C</span>
							<span>Y</span>
							<span>B</span>
							<span>E</span>
							<span>R</span>
							<span>D</span>
							<span>E</span>
							<span>V</span>
							<span>&nbsp;</span>
							<span>&nbsp;</span>
							<span>T</span>
							<span>E</span>
							<span>A</span>
							<span>M</span>
						</span>
					</div>
					<div className={styles.button}>
						<ButtonLeftIcon
							color="primary"
							label="DISMISS"
							icon="check"
							variant="contained"
							onClick={onClose}
							size="small"
							aria-label="dismiss"
						/>
					</div>
				</div>
			</div>
		);
	}
);

export default withStyles(styles2)(WelcomePopup);
