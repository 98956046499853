import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
	withHandlers,
	compose,
	lifecycle,
	renderComponent,
	branch
} from 'recompose';
import { withStateCreators } from '../../lib/utils/hoc';
import { postJSONPS } from '../../lib/utils/fetch';
import { composePS } from '@cd/pipe';
import styles from '../css/Login.module.scss';
import { either } from '../../lib/utils/utils';
import { CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { postGetStaticInfo } from '../../postGetStatic';
import postGetServerVar from './../../getServerVars';
import withAlert from './withAlert';
import CenterProgress from '../../CenterProgress';
import { VERSION } from '../../VERSION';
import Typography from '@mui/material/Typography';
import TextFieldCD from '../../dynamic/components/form/components/TextFieldCD';

/** postLogin :: string -> string  -> Promise(Either) */
const postLogin = (user, pwd, version) =>
	composePS(postJSONPS)('POST', `/login`, {
		body: JSON.stringify({ user, pwd, version })
	});

const enhance = compose(
	withRouter,
	withAlert,
	withStateCreators(
		{
			updateUser: () => user => ({ user }),
			updatePwd: () => pwd => ({ pwd }),
			updateShowPassword: () => showPassword => ({ showPassword }),
			updateLogInProgress: () => logInProgress => ({ logInProgress })
		},
		() => ({
			user: '',
			pwd: '',
			showPassword: false,
			version: VERSION
		})
	),
	lifecycle({
		componentDidMount() {
			postGetStaticInfo('signInMsg').then(
				either(console.log, loginText => {
					this.setState({ loginText });
				})
			);

			postGetServerVar('loginRegistrationButton').then(
				either(console.log, loginRegistrationButton => {
					this.setState({ loginRegistrationButton });
				})
			);
		}
	}),
	withHandlers({
		onShowPassword:
			({ showPassword, updateShowPassword }) =>
			() => {
				updateShowPassword(!showPassword);
			},
		onUserChange:
			({ updateUser }) =>
			v => {
				updateUser(v.target.value);
			},
		onPwdChange:
			({ updatePwd }) =>
			v => {
				updatePwd(v.target.value);
			},
		onLogin:
			({
				user,
				pwd,
				version,
				onLogin,
				onOpenAlertDlg,
				updateLogInProgress
			}) =>
			() => {
				updateLogInProgress(true);
				postLogin(user, pwd, version).then(
					either(
						e => {
							updateLogInProgress(false);

							onOpenAlertDlg({
								message:
									"Can't login in." +
									(!e ? 'Network Error' : e),
								title: 'Error'
							});
						},
						e => {
							updateLogInProgress(false);
							onLogin(e);
						}
					)
				);
			},
		onEnter:
			({ user, pwd, onLogin, onOpenAlertDlg, updateLogInProgress }) =>
			e => {
				if (e.key === 'Enter') {
					updateLogInProgress(true);
					postLogin(user, pwd).then(
						either(
							e => {
								updateLogInProgress(false);

								onOpenAlertDlg({
									message:
										"Can't login in." +
										(!e ? 'Network Error' : e),
									title: 'Error'
								});
							},
							e => {
								updateLogInProgress(false);
								onLogin(e);
							}
						)
					);
				}
			}
	}),
	branch(({ loginText }) => !loginText, renderComponent(CenterProgress))
);

const styles2 = theme => ({
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit
	}
});

const Login = enhance(
	({
		user,
		pwd,
		onLogin,
		onUserChange,
		onPwdChange,
		loginRegistrationButton,
		onEnter,
		classes,
		loginText,
		logInProgress,
		onShowPassword,
		showPassword
	}) => (
		<div className={styles.wrap}>
			<div className={styles.loginText}>{loginText}</div>
			<div className={styles.island}>
				<div className={styles.textFields}>
					<div className={styles.title}>Login</div>
					<TextFieldCD
						id="user"
						name="user"
						type="text"
						label="Email"
						margin="normal"
						classes={classes}
						className={classes.textField}
						onUpdateData={onUserChange}
						value={user}
						data={{ user: user }}
						validation={{}}
						inputProps={{}}
						onKeyPress={onEnter}
					/>
					<TextFieldCD
						id="password"
						name="password"
						type="p"
						label="Password"
						margin="normal"
						className={classes.textField}
						onKeyPress={onEnter}
						classes={classes}
						validation={{}}
						inputProps={{}}
						data={{ password: pwd }}
						onUpdateData={onPwdChange}
						showPassword={showPassword}
						onShowPassword={onShowPassword}
					/>
					<Link
						className={styles.link + ' ' + styles.alignSelf}
						to="/forgotPassword">
						Forgot Password?
					</Link>
				</div>
				<Button
					disabled={!user || !pwd || logInProgress}
					color="primary"
					variant="contained"
					onClick={onLogin}
					type="submit">
					Login Now
				</Button>
				{(loginRegistrationButton & 2) !== 0 && (
					<div className={styles.registrationUnderLogin}>
						<Typography color="primary" variant="subtitle1">
							Don't have an account?
						</Typography>

						<Link className={styles.link} to="/register">
							Register Now!
						</Link>
					</div>
				)}
				{logInProgress ? (
					<div className={styles.progressWrap}>
						<CircularProgress size={48} />
					</div>
				) : null}
			</div>
		</div>
	)
);

export default withStyles(styles2)(Login);
