import React from 'react';
import {
	withHandlers,
	compose,
	branch,
	renderComponent,
	lifecycle,
	withPropsOnChange
} from 'recompose';

import { withStateCreators } from '../../lib/utils/hoc';
import { either } from '../../lib/utils/utils';
import styles from '../css/LandingHeader.module.scss';
import ButtonLeftIcon from '../../controls/components/ButtonLeftIcon';
import postGetServerVar from './../../getServerVars';
import { postGetStaticInfo } from '../../postGetStatic';
import CenterProgress from '../../CenterProgress';
import eLocalLogoSVG from '../../images/eLocalLogo.svg';
import WelcomePopup from './WelcomePopup';
import SVG from 'react-inlinesvg';
var deferredPrompt;

window.addEventListener('beforeinstallprompt', function (e) {
	// Prevent Chrome 67 and earlier from automatically showing the prompt
	e.preventDefault();
	// Stash the event so it can be triggered later.
	deferredPrompt = e;

	showAddToHomeScreen();
});

function showAddToHomeScreen() {
	var a2hsBtn = document.querySelector('.ad2hs-prompt');
	if (a2hsBtn) {
		a2hsBtn.style.display = 'block';

		a2hsBtn.addEventListener('click', addToHomeScreen);
	}
}

function addToHomeScreen() {
	var a2hsBtn = document.querySelector('.ad2hs-prompt'); // hide our user interface that shows our A2HS button
	if (a2hsBtn) {
		a2hsBtn.style.display = 'none'; // Show the prompt
	}
	deferredPrompt.prompt(); // Wait for the user to respond to the prompt
	deferredPrompt.userChoice.then(function (choiceResult) {
		if (choiceResult.outcome === 'accepted') {
			console.log('User accepted the A2HS prompt');
		} else {
			console.log('User dismissed the A2HS prompt');
		}

		deferredPrompt = null;
	});
}

const enhance = compose(
	withStateCreators(
		{
			updateUser: () => user => ({ user }),
			updatePwd: () => pwd => ({ pwd })
		},
		() => ({
			user: 'test',
			pwd: 'test'
		})
	),
	withHandlers({
		onRegister: ({ history }) => () => {
			history.push('/register');
		},
		onUserChange: ({ updateUser }) => v => {
			updateUser(v.target.value);
		}
	}),
	lifecycle({
		componentDidMount() {
			postGetStaticInfo('headerText').then(
				either(console.log, headerText => {
					this.setState({ headerText });
				})
			);
			postGetStaticInfo('headerTop').then(
				either(console.log, ([headerLocals, headerMotto]) => {
					this.setState({ headerLocals, headerMotto });
				})
			);
			postGetServerVar('loginRegistrationButton').then(
				either(console.log, loginRegistrationButton => {
					this.setState({ loginRegistrationButton });
				})
			);
		}
	}),
	branch(({ headerText }) => !headerText, renderComponent(CenterProgress)),
	withPropsOnChange(['location'], ({ location }) => ({
		isRegistration: location.pathname === '/register'
	}))
);
const LandingHeader = enhance(
	({
		onRegister,
		headerText = '',
		headerLocals = [''],
		headerMotto = [''],
		isRegistration,
		loginRegistrationButton
	}) => (
		<div className={styles.wrap}>
			<WelcomePopup />
			<div>
				<div className={styles.logoWrap}>
					<SVG
						viewBox="0 0 320 200"
						className={styles.svgWrap}
						src={eLocalLogoSVG}
					/>
					<div className={styles.toolbarText}>
						{headerLocals.map(text => (
							<span
								style={{
									fontSize:
										12 + 36 / headerLocals.length + 'px'
								}}>
								{text}
							</span>
						))}
					</div>
				</div>
			</div>
			<div className={styles.mainMsgText}>
				{headerMotto.map(text => (
					<span>{text}</span>
				))}
			</div>
			<div className={styles.smallMessage}>{headerText}</div>
			{!isRegistration && (loginRegistrationButton & 1) !== 0 ? (
				<>
					<div className={styles.noAccountMsg}>
						Don't have an account yet?
					</div>
					<ButtonLeftIcon
						color="secondary"
						label="REGISTER"
						icon="notification_important"
						variant="contained"
						onClick={onRegister}
					/>
				</>
			) : null}
		</div>
	)
);

export default LandingHeader;
