import React from 'react';
import * as R from 'ramda';
import { composePS } from '@cd/pipe';
import { hot } from 'react-hot-loader';
import { withRouter } from 'react-router';
import { withStateCreators } from '../../lib/utils/hoc';
import {
	withHandlers,
	compose,
	lifecycle,
	branch,
	renderComponent
} from 'recompose';
import styles from '../css/Register.module.scss';
import { postJSONPS } from '../../lib/utils/fetch';
import { either } from '../../lib/utils/utils';
import Form from '../../dynamic/components/form/components/Form';

import { REGISTER_FORM } from '../../lib/utils/formFields';
import { withForm } from '../../dynamic/components/form/components/formUtils';
import withAlert from './withAlert';
import { postGetStaticInfo, postGetServerVar } from '../../postGetStatic';
import CenterProgress from '../../CenterProgress';

const postGetRegistrationInfo = () =>
	composePS(postJSONPS)('POST', `/getRegistationInfo`, {
		body: JSON.stringify({})
	});

const postSetAccountInfo = data =>
	composePS(postJSONPS)('POST', `/setRegistationInfo`, {
		body: JSON.stringify({ data })
	});

const enhance = compose(
	withRouter,
	withAlert,
	withStateCreators(
		{
			updateFormSchema: () => schema => ({
				schema
			})
		},
		() => ({
			schema: null
		})
	),
	lifecycle({
		componentDidMount() {
			const { updateFormSchema } = this.props;

			postGetServerVar('registrationNoBookNo').then(
				either(console.log, omitBookNo => {
					const formSchema = omitBookNo
						? R.reject(R.propEq('name', 'bookNo'), REGISTER_FORM)
						: REGISTER_FORM;

					postGetServerVar('registrationAccessCodeMsg').then(
						either(console.log, accessCodeMsg => {
							if (accessCodeMsg && accessCodeMsg.length > 0) {
								const index = formSchema.findIndex(
									R.propEq('name', 'accessCode')
								);
								formSchema[index].helperText = accessCodeMsg;
							}
							updateFormSchema(formSchema);
						})
					);
				})
			);
		}
	}),
	branch(({ schema }) => schema === null, renderComponent(CenterProgress)),
	withForm({
		/* joiValidationSchema: null */
	}),
	withStateCreators(
		{
			updateStates: () => states => ({ states }),
			updateAll: () => all => all,
			updateRegistrationText: () => registrationText => ({
				registrationText
			})
		},
		() => ({
			states: [],
			registrationText: ''
		})
	),
	lifecycle({
		componentDidMount() {
			const { preProcessData, updateInitData, updateRegistrationText } =
				this.props;
			composePS(
				updateInitData,
				preProcessData,
				postGetRegistrationInfo
			)();

			postGetStaticInfo('registrationMsg').then(
				either(console.log, updateRegistrationText)
			);
		}
	}),
	branch(
		({ registrationText, registrationNoBookNo }) =>
			!registrationText || registrationNoBookNo === null,
		renderComponent(CenterProgress)
	),
	withHandlers({
		onUpdateAccount: () => () => {},
		onBackToLogin:
			({ history }) =>
			() => {
				history.push('/login');
			},
		onSave:
			({ data, onSave, onOpenAlertDlg, history }) =>
			() => {
				//onOpenAlertDlg();
				postSetAccountInfo(data).then(
					either(
						e =>
							onOpenAlertDlg({
								message: R.is(String, e)
									? e
									: JSON.stringify(e),
								title: 'Error'
							}),
						message => {
							onSave();
							onOpenAlertDlg({
								message,
								title: 'Success',
								okCallback: () => {
									history.push('/login');
								}
							});
						}
					)
				);
			}
	})
);

const Register = enhance(
	({
		validation = {},
		data,
		states,
		onUpdateData,
		showPassword,
		onShowPassword,
		onSave,
		onBackToLogin,
		editing,
		schema,
		touched,
		registrationText
	}) => (
		<div className={styles.wrap}>
			<div className={styles.registerText}>{registrationText}</div>
			<Form
				formTitle="REGISTER FOR eLocal"
				formFields={schema}
				comboBoxData={{ states }}
				data={data}
				onUpdateData={onUpdateData}
				showPassword={showPassword}
				onShowPassword={onShowPassword}
				onSave={onSave}
				onCancel={onBackToLogin}
				validation={validation}
				editing={true}
				touched={touched}
				buttonLabels={['REGISTER', 'LOGIN']}
				buttonIcons={['save', 'keyboard_backspace']}
			/>
		</div>
	)
);

export default hot(module)(Register);
