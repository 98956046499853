import * as R from 'ramda';
import { useRef, useEffect, useState } from 'react';
import ajs from 'animejs';
import { css } from '@emotion/react';

const lineStyle = css({
	position: 'absolute',
	top: 'var(--top)',
	right: '1px',
	width: '1px',
	height: '10px',
	backgroundColor: '#fff',
	boxShadow: '#fff 0px 0px 4px 1px',
	zIndex: '10'
});

const Dots = ({ count }) => {
	const [dots] = useState(R.times(R.identity, count));
	const container = useRef(null);

	useEffect(() => {
		ajs({
			targets: container.current.children,
			translateY: {
				duration: 2000,
				value: i => {
					console.log('xrandeom', i);

					return ajs.random(-90, 90);
				}
			},
			opacity: {
				delay: 300,
				value: 0,
				duration: 2000
			},
			duration: 2000,
			delay: ajs.stagger(100, { from: 'center' }),
			easing: 'spring(1, 60, 10, 0)'
		});
	}, []);
	return (
		<div ref={container}>
			{dots.map(({ v }, index) => (
				<div
					key={v}
					index={index}
					css={lineStyle}
					style={{ '--top': `${ajs.random(0, 90)}px` }}
				/>
			))}
		</div>
	);
};

const BurnLines = () => {
	return <Dots count={10} />;
};
export default BurnLines;
