import React from 'react';
import { withHandlers, compose } from 'recompose';
import AlertDialog from './AlertDialog';
import { withRouter } from 'react-router';
import { postJSONPS } from '../../lib/utils/fetch';
import { composePS } from '@cd/pipe';
import { either } from '../../lib/utils/utils';

/** postLogout ::  Promise(Either) */
const postLogout = (user, pwd) =>
	composePS(postJSONPS)('POST', `/logout`, {
		body: JSON.stringify({ user, pwd })
	});

const enhance = compose(
	withRouter,
	withHandlers({
		onOK:
			({ updateUser, history, onLogout }) =>
			v => {
				postLogout().then(
					either(console.log, () => {
						onLogout();
					})
				);
			},
		onCancel:
			({ history }) =>
			v => {
				history.push('/dashboard/view');
			}
	})
);

const Logout = enhance(({ onOK, onCancel, openDlg, title, message }) => (
	<AlertDialog
		openDlg={false}
		title={title}
		message={message}
		buttonText={['OK', 'CANCEL']}
		onOK={onOK}
		onCancel={onCancel}
	/>
));

export default Logout;
