import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import withStyles from '@mui/styles/withStyles';
import { withHandlers, lifecycle } from 'recompose';
import { composePS } from '@cd/pipe';
import Icon from '@mui/material/Icon';
import ListSubheader from '@mui/material/ListSubheader';
import eLocalLogoSVG from '../../images/eLocalLogo.svg';
import SVG from 'react-inlinesvg';
import styles2 from '../css/Drawer.module.scss';
import { either, noop } from '../../lib/utils/utils';
import { postJSONPS } from '../../lib/utils/fetch';
import cn from 'classnames';
import BurnLines from './BurnLines';

/** postOrgName ::  Promise(Either) */
const postOrgName = () =>
	composePS(postJSONPS)('POST', `/orgInfo`, {
		body: JSON.stringify({})
	});

const drawerWidth = 320;

const styles = theme => ({
	root: {
		display: 'flex'
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`
		}
	},
	menuButton: {
		marginRight: 20,
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3
	},
	svg: {
		width: '48px',
		height: '48px'
	}
});

const enhanceCI = withHandlers({
	onChangeURL:
		({ href, onChangeURL }) =>
		() => {
			onChangeURL(href);
		}
});
const ClickableItem = enhanceCI(
	({ title, onChangeURL, iconName, flash, flashIconName }) => {
		const [run, setRun] = useState(true);
		useEffect(() => {
			setTimeout(() => {
				setRun(false);
			}, 8000);
		}, []);
		return (
			<ListItem button key={title} onClick={onChangeURL}>
				<ListItemIcon>
					<Icon className={cn({ [styles2.flash]: flash })}>
						{run && flash ? flashIconName : iconName}
					</Icon>
				</ListItemIcon>
				<ListItemText primary={title} />
			</ListItem>
		);
	}
);

const enhance = R.compose(
	withHandlers({
		onChangeURL:
			({ onChangeURL }) =>
			v => {
				onChangeURL(v);
			}
	}),
	lifecycle({
		componentDidMount() {
			postOrgName().then(
				either(
					console.log,
					({ orgInfo: { orgName, address1, address2, phone } }) => {
						this.setState({ orgName, address1, address2, phone });
					}
				)
			);
		}
	})
);

const eResponsiveDrawer = enhance(
	({
		classes,
		theme,
		navList = [],
		onChangeURL,
		mobileOpen,
		handleDrawerToggle = noop,
		orgName,
		address1,
		address2,
		phone
	}) => {
		const drawer = (
			<div>
				<div className={classes.toolbar}>
					<div className={styles2.logoWrap}>
						<div className={styles2.svgWrap}>
							<SVG
								viewBox="0 0 520 520"
								className={styles2.svg}
								src={eLocalLogoSVG}
							/>
							<BurnLines />
						</div>

						<div className={styles2.burnLine} />
						<div className={styles2.toolbarText}>
							<span>{orgName}</span>
							<span>{address1}</span>
							<span>{address2}</span>
							<span>{phone}</span>
						</div>
					</div>
				</div>
				<Divider />
				<List>
					{navList.map(
						({
							title,
							isDivider,
							isHeader,
							iconName,
							href,
							flash,
							flashIconName
						}) =>
							isDivider ? (
								<Divider key={href} />
							) : isHeader ? (
								<ListSubheader key={href} component="div">
									{title}
								</ListSubheader>
							) : (
								<ClickableItem
									flash={flash}
									flashIconName={flashIconName}
									key={href}
									href={href}
									title={title}
									onChangeURL={onChangeURL}
									iconName={iconName}
								/>
							)
					)}
				</List>
			</div>
		);

		return (
			<nav className={classes.drawer}>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}

				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden mdDown>
					<Drawer
						classes={{
							paper: classes.drawerPaper
						}}
						variant="permanent"
						open>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
		);
	}
);

export default withStyles(styles, { withTheme: true })(eResponsiveDrawer);
