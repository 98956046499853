import React from 'react';
import * as R from 'ramda';
import { composePS } from '@cd/pipe';
import { hot } from 'react-hot-loader';
import { withRouter } from 'react-router';
import { withStateCreators } from '../../lib/utils/hoc';
import { withHandlers, compose, lifecycle, withPropsOnChange } from 'recompose';
import styles from '../css/Register.module.scss';
import { postJSONPS } from '../../lib/utils/fetch';
import { either } from '../../lib/utils/utils';
import Form from '../../dynamic/components/form/components/Form';
import { SECURITY_Q_FORM } from '../../lib/utils/formFields';
import { withForm } from '../../dynamic/components/form/components/formUtils';
import withAlert from './withAlert';

const postSetSecurityInfo = data =>
	composePS(postJSONPS)('POST', `/setSecurityQuestions`, {
		body: JSON.stringify({ data })
	});

const enhance = compose(
	withRouter,
	withAlert,
	withForm({ joiValidationSchema: SECURITY_Q_FORM }),
	withStateCreators(
		{
			updateFieldData: () => fieldData => ({ fieldData }),
			updateAll: () => all => all
		},
		() => ({
			states: [],
			fieldData: {}
		})
	),
	lifecycle({
		componentDidMount() {
			const { data, bookNo, email } = this.props.location.state;
			const { updateFieldData, updateInitData } = this.props;
			updateInitData({
				email,
				bookNo,
				answer1: '',
				answer2: '',
				password: ''
			});
			updateFieldData(data);
		}
	}),
	withHandlers({
		onUpdateAccount: () => () => {},
		onBackToRecover:
			({ history }) =>
			() => {
				history.push('/forgotPassword');
			},
		onSave:
			({ data, onSave, onOpenAlertDlg, history }) =>
			() => {
				//onOpenAlertDlg();
				postSetSecurityInfo(data).then(
					either(
						e =>
							onOpenAlertDlg({
								message: R.is(String, e)
									? e
									: JSON.stringify(e),
								title: 'Error'
							}),
						message => {
							onSave();
							onOpenAlertDlg({
								message,
								title: 'Success',
								okCallback: () => {
									history.push('/login');
								}
							});
						}
					)
				);
			}
	}),
	withPropsOnChange(['fieldData'], ({ fieldData }) => ({
		formFields: R.map(
			({ name, ...other }) =>
				name === 'answer1'
					? { name, ...other, label: fieldData.Question_One }
					: name === 'answer2'
					? { name, ...other, label: fieldData.Question_Two }
					: { name, ...other },
			SECURITY_Q_FORM
		)
	}))
);

const RecoverPassword = enhance(
	({
		validation = {},
		data,
		onUpdateData,
		showPassword,
		onShowPassword,
		onSave,
		onBackToRecover,
		editing,
		touched,
		formFields
	}) => (
		<div className={styles.wrap} style={{ marginTop: '128px' }}>
			<Form
				alwaysTouched
				formTitle="ANSWER QUESTION/SET PASSWORD"
				formFields={formFields}
				data={data}
				onUpdateData={onUpdateData}
				showPassword={showPassword}
				onShowPassword={onShowPassword}
				onSave={onSave}
				onCancel={onBackToRecover}
				validation={validation}
				editing={true}
				touched={touched}
				buttonLabels={['RESET PASSWORD', 'RECOVERY']}
				buttonIcons={['lock', 'keyboard_backspace']}
			/>
		</div>
	)
);

export default hot(module)(RecoverPassword);
