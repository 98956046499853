import React from 'react';
import * as R from 'ramda';
import { hot } from 'react-hot-loader';
import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styles from '../css/Form.module.scss';
import { compose, withProps, branch, renderComponent } from 'recompose';
import ButtonLeftIcon from '../../../../controls/components/ButtonLeftIcon';
import CenterProgress from '../../../../CenterProgress';
import TextFieldCD from './TextFieldCD';

const styles2 = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	container: {
		position: 'relative',
		display: 'flex',
		//flexWrap: 'wrap',
		flexDirection: 'column',
		backgroundColor: '#474747',
		padding: '48px 8px 16px',
		borderRadius: '4px',
		flex: '1 0 auto',
		overflow: 'visible'
	},
	formTitle: {
		position: 'absolute',
		top: '-22px',
		left: '16px',
		padding: '16px',
		borderRadius: '4px ',
		display: 'inline-block'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit
	},
	dense: {
		marginTop: 16
	},
	menu: {
		whiteSpace: 'pre-wrap'
	}
});

const enhance = compose(
	withStyles(styles2),
	withProps(({ editing, ...other }) => {
		return {
			inputProps: editing ? {} : { disabled: true },
			...other
		};
	}),
	branch(
		({ data }) => !data || R.isEmpty(data),
		renderComponent(() => <CenterProgress color="secondary" />)
	)
);

const Form = enhance(
	({
		classes,
		onUpdateData,
		data,
		comboBoxData,
		showPassword,
		onShowPassword,
		onSave,
		onCancel,
		formFields,
		validation,
		inputProps,
		editing,
		editCmp,
		touched,
		hideCancel,
		formTitle = '',
		buttonLabels = ['SAVE', 'CANCEL'],
		buttonIcons = ['save', 'cancel'],
		alwaysTouched
	}) => {
		const [okLabel, cancelLabel] = buttonLabels;
		const [okIcon, cancelIcon] = buttonIcons;
		return (
			<form className={classes.container} noValidate autoComplete="off">
				<div className={classes.formTitle + ' ' + styles.formTitle}>
					{formTitle}
				</div>
				<div className={styles.buttonIsland}>
					{editing ? (
						<>
							{hideCancel ? null : (
								<ButtonLeftIcon
									label={cancelLabel}
									icon={cancelIcon}
									variant="outlined"
									onClick={onCancel}
									aria-label={cancelLabel}
								/>
							)}
							&nbsp;
							<ButtonLeftIcon
								label={okLabel}
								icon={okIcon}
								disabled={
									!R.isEmpty(validation) ||
									(!alwaysTouched && !touched)
								}
								variant="contained"
								aria-label={okLabel}
								onClick={onSave}
								className={classes.button}
								color="secondary"
							/>
						</>
					) : (
						editCmp
					)}
				</div>
				{formFields.map(
					({
						name,
						label,
						type,
						dataSourceName,
						mask,
						helperText,
						disabled,
						shrink
					}) =>
						type === 't' || type === 'p' || type === 'm' ? (
							<TextFieldCD
								name={name}
								label={label}
								classes={classes}
								type={type}
								disabled={disabled}
								validation={validation}
								mask={mask}
								inputProps={inputProps}
								shrink={shrink}
								data={data}
								onUpdateData={onUpdateData}
								helperText={helperText}
								showPassword={showPassword}
								onShowPassword={onShowPassword}
							/>
						) : (
							<FormControl
								className={classes.formControl}
								variant="outlined"
								key={name}>
								<InputLabel
									shrink={data[name] || shrink}
									ref={() => {}}
									htmlFor="outlined-age-simple">
									{label}
								</InputLabel>
								<Select
									disabled={disabled}
									value={data[name] || -1}
									onChange={onUpdateData}
									id={name}
									name={`${name}`}
									displayEmpty
									className={classes.selectEmpty}
									classes={{
										selectMenu: classes.menu
									}}
									input={
										<OutlinedInput
											labelWidth="50"
											name="State"
											id="outlined-age-simple"
										/>
									}
									{...inputProps}>
									<MenuItem value="" disabled>
										Placeholder
									</MenuItem>
									{(comboBoxData[dataSourceName] &&
										comboBoxData[dataSourceName].map(
											({ text, value }) => (
												<MenuItem
													key={text}
													value={value}>
													{text}
												</MenuItem>
											)
										)) ||
										null}
								</Select>
							</FormControl>
						)
				)}
			</form>
		);
	}
);

export default hot(module)(Form);
