import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Markdown from 'react-markdown';

const AlertDialog = ({
	openDlg,
	title,
	message,
	buttonText: [okText, cancelText],
	onOK,
	onCancel,
	fullScreen = false
}) => (
	<Dialog
		open={openDlg !== 0}
		TransitionComponent={Slide}
		keepMounted
		onClose={onCancel}
		aria-labelledby="alert-dialog-slide-title"
		aria-describedby="alert-dialog-slide-description"
		fullScreen={fullScreen}>
		<DialogTitle id="alert-dialog-slide-title">{title || ' '}</DialogTitle>
		<DialogContent>
			<Markdown>{message}</Markdown>
		</DialogContent>

		<DialogActions>
			<Button variant="contained" onClick={onOK} color="primary">
				{okText}
			</Button>
			{cancelText ? (
				<Button variant="outlined" onClick={onCancel} color="secondary">
					{cancelText}
				</Button>
			) : null}
		</DialogActions>
	</Dialog>
);

export default AlertDialog;
