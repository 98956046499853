const {
	preProcessFields,
	postProcessFields
} = require('../../../../lib/utils/utils');

const {
	createJoiValidationSchema,
	validateData
} = require('../../../../lib/utils/utils');
const { withStateCreators } = require('../../../../lib/utils/hoc');
const { withPropsOnChange, withHandlers } = require('recompose');
const deepEqual = require('deep-equal');
const R = require('ramda');

const withForm = ({ joiValidationSchema }) =>
	R.compose(
		withStateCreators(
			{
				updateData: ({ data, origData }) => newData => {
						const nextData = R.mergeRight(data, newData);
						return {
							data: nextData,
							touched: !deepEqual(nextData, origData)
						};
					},
				updateInitData: () => data => {
					return { data, origData: data };
				},
				updateEditing: () => editing => ({ editing }),
				updateShowPassword: () => showPassword => ({ showPassword }),
				updateAll: () => all => all
			},
			({ editing, hideCancel, schema }) => {
				const finalSchema = schema || joiValidationSchema;
				return {
					touched: false,
					data: {},
					origData: {},
					hideCancel: hideCancel !== undefined ? hideCancel : false,
					editing: editing !== undefined ? editing : false,
					showPassword: false,
					validationSchema: createJoiValidationSchema(finalSchema),
					preProcessData: preProcessFields(finalSchema),
					postProcessData: postProcessFields(finalSchema)
				};
			}
		),
		withHandlers({
			onShowPassword: ({ showPassword, updateShowPassword }) => () => {
					updateShowPassword(!showPassword);
				},
			onSave: ({ updateAll, updateEditing, data }) => () => {
					updateAll({
						editing: false,
						origData: data,
						data,
						touched: false
					});
				},
			onCancel: ({ updateAll, origData }) => () => {
				updateAll({ editing: false, data: origData, touched: false });
				},
			onEdit: ({ updateEditing }) => () => {
					updateEditing(true);
				},
			onUpdateData: ({ updateData }) => e => {
					R.compose(
						updateData,
						R.objOf(e.target.id || e.target.name)
					)(e.target.value);
				}
		}),
		withPropsOnChange(['data'], ({ data, validationSchema }) => ({
			validation: validateData({
				data,
				validationSchema,
				options: {
					abortEarly: false
				}
			})
		}))
	);

module.exports = {
	withForm
};
