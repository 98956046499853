import React, { Component } from 'react';
import * as R from 'ramda';

/**\
 *
 */
const withStateEx = R.curry((stateName, updateStateFnName, state, Cmp) => {
	class FreshState extends Component {
		constructor(props) {
			super(props);
			this.state = {
				[stateName]: R.is(Function, state) ? state(props) : state
			};
			this[updateStateFnName] = this[updateStateFnName].bind(this);
		}

		render() {
			const props = R.mergeRight(this.props, {
				[updateStateFnName]: this[updateStateFnName],
				[stateName]: this.state[stateName]
			});
			return <Cmp {...props} />;
		}
	}

	FreshState.prototype[updateStateFnName] = function (state) {
		this.setState({ [stateName]: state });
	};

	return FreshState;
});

const withStateCreators = R.curry((stateCreators, state, Cmp) => {
	const stateCreatorsPairs = R.toPairs(stateCreators);

	class FreshState extends Component {
		constructor(props) {
			super(props);

			R.forEach(([fnName, fn]) => {
				this[fnName] = this.updateState(fn);
			}, stateCreatorsPairs);

			this.fnNameObj = R.compose(
				R.pick(R.__, this),
				R.keys
			)(stateCreators);
			this.state = R.is(Function, state)
				? state(R.mergeRight(props, this.fnNameObj))
				: state;
		}

		updateState(fn) {
			var that = this;
			//const fnNameObj = R.compose(R.pick(R.__, this), R.keys)(stateCreators);

			return function (state, callback) {
				const p = new Promise(resolve => {
					that.setState((prevState, props) =>
						R.compose(
							R.when(
								() => R.is(Function, callback),
								R.tap(callback)
							),
							R.tap(resolve),
							R.mergeRight
						)(
							prevState,
							fn(
								R.compose(
									R.mergeRight(props),
									R.mergeRight(prevState)
								)(that.fnNameObj)
							)(
								R.is(Function, state)
									? state(R.mergeRight(props, prevState))
									: state
							)
						)
					);
				});
				return p;
			};
		}

		render() {
			const fnNameObj = R.compose(
				R.pick(R.__, this),
				R.keys
			)(stateCreators);

			const props = R.mergeRight(this.props, {
				...fnNameObj,
				...this.state
			});
			return <Cmp {...props} />;
		}
	}

	return FreshState;
});

export { withStateEx, withStateCreators };
