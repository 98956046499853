import React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
	button: {
		//margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	}
});

const ButtonLeftIcon = ({ classes, label, icon, ...other }) => (
	<Button {...other} className={classes.button}>
		<Icon className={classes.leftIcon}>{icon}</Icon>
		{label}
	</Button>
);

export default withStyles(styles)(ButtonLeftIcon);
