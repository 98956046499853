import React from 'react';
import { CircularProgress } from '@mui/material';

const CenterProgress = props => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flex: 1
		}}
	>
		<CircularProgress size={68} {...props} />
	</div>
);

export default CenterProgress;
