import React from 'react';
import { CircularProgress } from '@mui/material';

class DynamicImport extends React.Component {
	state = {
		component: null
	};
	componentDidMount() {
		this.props.load().then(component => {
			this.setState(() => ({
				component: component.default ? component.default : component
			}));
		});
	}
	render() {
		return this.props.children(this.state.component, this.props);
	}
}

const DynamicDefaultCmp = (Component, props) =>
	Component === null ? (
		<CircularProgress color="secondary" />
	) : (
		<Component {...props} />
	);

export { DynamicImport, DynamicDefaultCmp };
