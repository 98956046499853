import React from 'react';
import { withHandlers, compose, lifecycle } from 'recompose';

import Button from '@mui/material/Button';
/* const TermsLink = props => <Link to="/static/privacy" {...props} />
const TermsLink = props => <Link to="/static/terms" {...props} /> */

import { withStateCreators } from '../../lib/utils/hoc';
import { either } from '../../lib/utils/utils';

import styles from '../css/LandingFooter.module.scss';
import withAlert from './withAlert';
import {
	postGetStaticInfo,
	postGetServerVar,
	postGetAppVersion
} from '../../postGetStatic';
import Tooltip from '@mui/material/Tooltip';
import { VERSION } from '../../VERSION';

const enhance = compose(
	withAlert,
	withStateCreators(
		{
			updateAll: () => all => all
		},
		() => ({})
	),
	withHandlers({
		onUserChange: ({ updateUser }) => v => {
			updateUser(v.target.value);
		},
		onGetStatic: ({ onOpenAlertDlg, updateAll }) => info => {
			postGetStaticInfo(info).then(
				either(console.log, data => {
					onOpenAlertDlg({
						message: data,
						title: '',
						okCallback: () => {}
					});
				})
			);
		}
	}),
	lifecycle({
		componentDidMount() {
			postGetServerVar('agreements').then(
				either(console.log, agreementStatus => {
					this.setState({ agreementStatus });
				})
			);

			postGetAppVersion().then(
				either(console.log, appVersion => {
					this.setState({ appVersion });
				})
			);
		}
	})
);
const LandingFooter = enhance(
	({ agreementStatus = {}, onGetStatic, appVersion }) => (
		<div className={styles.wrap}>
			<div className={styles.mainMsgText}>
				{agreementStatus.terms ? (
					<Button onClick={() => onGetStatic('terms')}>
						Terms and Conditions
					</Button>
				) : null}
				{agreementStatus.privacy ? (
					<Button onClick={() => onGetStatic('privacy')}>
						Privacy
					</Button>
				) : null}
				{agreementStatus.refunds ? (
					<Button onClick={() => onGetStatic('refunds')}>
						Refunds
					</Button>
				) : null}
			</div>

			<div className={styles.cd}>
				CyberDev &copy;{' '}
				<Tooltip title={`Server: ${appVersion} Client: ${VERSION}`}>
					<span>2022 v.{appVersion}</span>
				</Tooltip>
			</div>
		</div>
	)
);

export default LandingFooter;
