import React from 'react';
import * as R from 'ramda';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { postJSONPS } from '../../lib/utils/fetch';
import { composePS } from '@cd/pipe';
import { either } from '../../lib/utils/utils';

import {
	lifecycle,
	compose,
	withPropsOnChange,
	branch,
	renderComponent
} from 'recompose';
import CenterProgress from '../../CenterProgress';
import { css } from '@emotion/react';

const postGetAppVersion = () =>
	composePS(postJSONPS)('GET', `/config/get/app/version`, {});

const getTitle = pathname => {
	switch (pathname) {
		case '/index':
			return 'Inspector';

		case '/results':
			return 'Results';

		case '/login':
			return 'Login';

		default:
			return 'eLocal';
	}
};

const enhance = compose(
	withRouter,
	lifecycle({
		componentDidMount() {
			const {
				onSearchValue,
				location: { pathname }
			} = this.props;
			this.setState({ currentFormTitle: getTitle(pathname) });

			this.props.history.listen(({ pathname }) => {
				this.setState({ currentFormTitle: getTitle(pathname) });
				onSearchValue({ target: { value: '' } });
			});

			postGetAppVersion().then(
				either(console.log, v => this.setState({ appVersion: v }))
			);
		}
	}),
	withPropsOnChange(['navList'], ({ navList }) => ({
		searchFormList:
			R.compose(
				R.pluck('href'),
				R.filter(R.propEq('hasSearch', true))
			)(navList) || []
	})),
	branch(
		({ navList }) => !navList || navList.length === 0,
		renderComponent(CenterProgress)
	)
);

const searchStyles = css({
	display: 'flex',
	alignItems: 'center',
	color: 'green',
	marginLeft: 'auto',
	marginRight: '4px'
});

const Search = ({ searchValue, onSearchValue }) => (
	<div css={searchStyles}>
		<SearchIcon />
		<TextField
			inputProps={{
				sx: { padding: '4px', maxWidth: 160 },
				'aria-label': 'search'
			}}
			placeholder="Search…"
			value={searchValue}
			onChange={onSearchValue}
		/>
	</div>
);

const IndexAppBar = enhance(
	({
		classes,
		currentFormTitle = '',
		onOpenNavDrawer,
		searchValue,
		onSearchValue,
		searchFormList,
		appVersion
	}) => (
		<AppBar position="static">
			<Toolbar sx={{ pr: 0, pl: 0, flex: '0 0 auto' }}>
				<IconButton
					color="inherit"
					aria-label="Open drawer"
					onClick={onOpenNavDrawer}
					size="large">
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" color="inherit" noWrap>
					{currentFormTitle}
				</Typography>
				<Typography
					css={{ marginLeft: '4px', marginTop: '-8px' }}
					variant="caption"
					color="secondary"
					noWrap>
					{appVersion}
				</Typography>

				<Route
					path={searchFormList}
					render={() => (
						<Search
							searchValue={searchValue}
							onSearchValue={onSearchValue}
						/>
					)}
				/>
			</Toolbar>
		</AppBar>
	)
);

export default IndexAppBar;
