if (RegExp.prototype.flags === undefined) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(RegExp.prototype, 'flags', {
		configurable: true,
		get: function () {
			return this.toString().match(/[gimsuy]*$/)[0];
		},
		includes: function () {
			return this.toString().match(/[gimsuy]*$/)[0];
		}
	});
}

const { composePS } = require('@cd/pipe');
const Promise = require('bluebird');
const Either = require('data.either');
const joi = require('joi');
const R = require('ramda');
//const validate = joi.validate.bind(joi);
const { format } = require('date-fns');
Either.prototype.toJSON = function () {
	return { value: this.value, isRight: this.isRight, isLeft: this.isLeft };
};
function noop() {}

const STR_ERROR_PROMISE_RESOLUTION =
	'Undefined or null returned as Promise resolution!';

const isRight = e => e.isRight;
const isLeft = e => e.isLeft;

const Left = v => Either.Left(v);
const Right = v => Either.Right(v);

const fnToSafeFn =
	fn =>
	(...args) =>
		new Promise((resolve, reject) =>
			fn(...args)
				.then(r =>
					resolve(
						r === undefined || r === null
							? Left(STR_ERROR_PROMISE_RESOLUTION)
							: Right(r)
					)
				)
				.catch(R.compose(resolve, Left))
		);

/**
 * either :: (a -> c) -> (b -> c) -> Either a b -> c
 * @var {function} either
 */
const either = R.curry((l, r, either) =>
	either.isLeft ? l(either.value) : r(either.value)
);

/** valueOfEither :: Either -> *
 *  @type {function}
 */
const valueOfEither = either(R.identity, R.identity);

/** [Either] -> Either([]) */
const sequenceEither = R.compose(
	R.ifElse(R.compose(isRight, R.head), R.compose(Right, R.last), R.head),
	R.reduce(
		(pair, either) => {
			return isRight(R.head(pair)) && isRight(either)
				? [either, R.append(valueOfEither(either), R.last(pair))]
				: [isLeft(R.head(pair)) ? R.head(pair) : either, []];
		},
		[Right(1), []]
	)
);

/** promiseAndSequence :: [S(P)] -> S[P] */
const promiseAndSequence = composePS(sequenceEither, Promise.all);
const indexMap = R.addIndex(R.map);
/** renameKeys :: {a : 'b'} -> {a} -> {b} */
const renameKeys = R.curry((keysMap, obj) => {
	return R.reduce(
		(acc, key) => {
			acc[keysMap[key] || key] = obj[key];
			return acc;
		},
		{},
		R.keys(obj)
	);
});

// promisifySafe :: (*... -> cb) -> (*... -> Promise(either))
const promisifySafe =
	fn =>
	(...args) => {
		return new Promise(resolve => {
			fn(...args, (err, val) => {
				resolve(
					err
						? Left(err)
						: val === undefined || val === null
						? Left(STR_ERROR_PROMISE_RESOLUTION)
						: Right(val)
				);
			});
		});
	};

/** promisifySafeForNullReturn :: (*... -> cb) -> (*... -> Promise(either)) */
const promisifySafeForNullReturn =
	fn =>
	(...args) => {
		return new Promise(resolve => {
			fn(...args, (err, val) => {
				resolve(
					err
						? Left(err)
						: val === undefined || val === null
						? Right({})
						: Right(val)
				);
			});
		});
	};

/** createJoiValidationSchema :: [] -> {} */
const createJoiValidationSchema = R.compose(
	obj => joi.object(obj),
	R.reduce((acc, { name, validate }) => R.assoc(name, validate, acc), {})
);

const validateData = params =>
	R.compose(
		R.ifElse(
			joiResults =>
				joiResults && joiResults.error && joiResults.error.details,
			R.compose(
				R.reduce(
					(acc, { message, context: { key } }) =>
						R.assoc(key, message, acc),
					{}
				),
				R.path(['error', 'details'])
			),
			R.always([])
		),
		({ data, validationSchema, options }) =>
			validationSchema.validate(data, options)
	)(params);

const processFields = R.curry((fnName, schema, data) => {
	return R.compose(
		lookup =>
			R.compose(
				R.reduce((acc, arr) => {
					const key = arr[0];
					const val = R.isNil(arr[1]) ? '' : arr[1];
					return R.assoc(
						key,
						(lookup[key] && lookup[key](val)) || val,
						acc
					);
				}, {}),
				R.toPairs
			)(data),
		R.reduce((acc, item) => R.assoc(item.name, item[fnName], acc), {})
	)(schema);
});

const formatPhone = phone =>
	(phone &&
		phone.length &&
		'(' +
			phone.slice(0, 3) +
			') ' +
			phone.slice(3, 6) +
			'-' +
			phone.slice(6, 10)) ||
	'';

/** preProcessFields ::[] -> {} -> {} */
const preProcessFields = processFields('preProcess');
/** postProcessFields ::[] -> {} -> {} */
const postProcessFields = processFields('postProcess');

const round2 = v => Math.round(v * 100) / 100;

/**
 * @typedef {Object} FeeObj
 *
 * @property {String} cur_date
 * @property {String} amount
 */

/**
 * build the fee table to be displayed to the user
 *
 * @param {FeeObj[]} feeTable
 * @param {Number} currentAmount
 * @returns {Array}
 */
function buildFeeTable(feeTable, currentAmount) {
	const duesComboValueArr = [];
	let _currentAmount = currentAmount;
	let feeIndex = 0; // calculate a fee index

	for (; feeIndex < feeTable.length; feeIndex++) {
		const feeAmt =
			feeIndex === 0 ? 0 : Number.parseFloat(feeTable[feeIndex].amount);
		_currentAmount -= feeAmt; // decrease _currentAmount until it's less than 0
		if (_currentAmount < 0) {
			break;
		}
	}
	// start from feeindex
	for (let fi = feeIndex, rollingAmount = 0; fi < feeTable.length; fi++) {
		const feeAmt = fi === 0 ? 0 : Number.parseFloat(feeTable[fi].amount);

		rollingAmount += +(
			fi === feeIndex && _currentAmount ? Math.abs(_currentAmount) : feeAmt
		).toFixed(2);

		const rollingAmtStr = rollingAmount.toString().match(/\./)
			? rollingAmount
			: `${rollingAmount}.00`;

		const date = feeTable[fi].cur_date;
		const parsedNextMonth = new Date(Date.parse(date));
		parsedNextMonth.setMonth(parsedNextMonth.getMonth() + 1);
		parsedNextMonth.setDate(parsedNextMonth.getDate() - 1);

		if (rollingAmount > 0) {
			duesComboValueArr.push({
				value: rollingAmount,
				text: `${format(parsedNextMonth, 'MMM-yyyy')} => $${rollingAmtStr}`
			});
		} else break;
	}
	return duesComboValueArr;
}

module.exports = {
	buildFeeTable,
	round2,
	indexMap,
	preProcessFields,
	postProcessFields,
	validateData,
	createJoiValidationSchema,
	formatPhone,
	promisifySafe,
	promisifySafeForNullReturn,
	noop,
	Left,
	Right,
	isLeft,
	isRight,
	either,
	fnToSafeFn,
	promiseAndSequence,
	renameKeys
};
