import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.scss';
import { composePS } from '@cd/pipe';
import ErrorBoundary from './ErrorBoundary';
import { ThemeProvider } from '@mui/material/styles';
import Wrapper from './Wrapper';
import { lifecycle, compose, withHandlers } from 'recompose';
import { withRouter, Switch } from 'react-router';
import { withStateCreators } from './lib/utils/hoc';
import Login from './login/components/Login';
import Register from './login/components/Register';
import Logout from './login/components/Logout';
import { either } from './lib/utils/utils';
import { postJSONPS } from './lib/utils/fetch';
import AlertDlgContext from './AlertDlgContext';
import AlertDialog from './login/components/AlertDialog';
import ForgotPassword from './login/components/ForgotPassword';
import CssBaseline from '@mui/material/CssBaseline';
import RecoverPassword from './login/components/RecoverPassword';
import { DynamicImport, DynamicDefaultCmp } from './DynamicImport';
import LandingHeader from './login/components/LandingHeader';
import LandingFooter from './login/components/LandingFooter';
import theme from './theme';
import { ThemeProviderCD } from '@cd/rhf';

const debug = false;
/** postSessionValid ::  Promise(Either) */
const postSessionValid = () =>
	composePS(postJSONPS)('POST', `/sessionValid`, {
		body: JSON.stringify({})
	});

/* const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: { main: blue[700] }, // Purple and green play nicely together.
		secondary: { main: orange[500] } // This is just green.A700 as hex.
	},
	typography: { useNextVariants: true },
	breakpoints: {
		values: {
			xs: 0,
			sm: 500,
			md: 960,
			lg: 1280,
			xl: 1920
		}
	}
}); */

const changeAuthRoute = (isAuth, securityQuestions, history) =>
	debug
		? 1
		: history.push(
				isAuth
					? securityQuestions
						? '/dashboard/view'
						: '/security/f'
					: '/login'
		  );

const enhance = compose(
	withRouter,
	withStateCreators(
		{
			updateIsAuth:
				({ history }) =>
				isAuth => {
					setTimeout(
						() => changeAuthRoute(isAuth, undefined, history),
						50
					);
					return { isAuth };
				},
			updateAlertDlgOpen:
				() =>
				({
					alertDlgOpen,
					message,
					title,
					buttonText,
					okCallback,
					fullScreen
				}) => ({
					alertDlgOkCallback: okCallback,
					alertDlgOpen,
					alertDlgMsg: message,
					alertDlgButtonText: buttonText,
					alertDlgTitle: title,
					alertDlgFullScreen: fullScreen
				})
		},
		() => ({ isAuth: false, alertDlgOpen: 0 })
	),
	lifecycle({
		componentDidMount() {
			const { history } = this.props;

			postSessionValid().then(
				either(console.log, ({ isAuth, securityQuestions }) => {
					changeAuthRoute(isAuth, securityQuestions, history);
					this.setState({ securityQuestions });
				})
			);
		}
		/* componentDidUpdate(prevProps) {
			const { isAuth, history } = this.props;

			if (prevProps.isAuth !== isAuth && isAuth) {
				postSessionValid().then(
					either(console.log, ({ isAuth, securityQuestions }) => {
						changeAuthRoute(isAuth, securityQuestions, history);
					})
				);
			}
		} */
	}),
	withHandlers({
		onLogin:
			({ history }) =>
			v => {
				if (v === 'OK' || v === 'OKS') {
					changeAuthRoute(true, v === 'OK', history);
				}
			},
		onLogout:
			({ updateIsAuth }) =>
			() => {
				updateIsAuth(false);
			},
		onOpenAlertDlg:
			({ updateAlertDlgOpen }) =>
			({
				title,
				buttonText,
				message,
				okCallback,
				fullScreen = false
			}) => {
				updateAlertDlgOpen({
					alertDlgOpen: true,
					buttonText,
					message,
					title,
					okCallback,
					fullScreen
				});
			},
		onCloseAlertDlg:
			({ updateAlertDlgOpen, alertDlgOkCallback }) =>
			() => {
				updateAlertDlgOpen({
					alertDlgOpen: 0,
					alertDlgOkCallback: null,
					buttonText: null
				});
				if (alertDlgOkCallback) {
					alertDlgOkCallback();
				}
			},
		onCancelAlertDlg:
			({ updateAlertDlgOpen, alertDlgCancelCallback }) =>
			() => {
				updateAlertDlgOpen({
					alertDlgOpen: 0,
					alertDlgOkCallback: null,
					buttonText: null
				});
				if (alertDlgCancelCallback) {
					alertDlgCancelCallback();
				}
			}
	})
);

const LoginCtrl = enhance(
	({
		onLogin,
		onLogout,
		onOpenAlertDlg,
		onCloseAlertDlg,
		onCancelAlertDlg,
		alertDlgOpen,
		alertDlgMsg,
		alertDlgButtonText,
		alertDlgTitle,
		alertDlgFullScreen,
		securityQuestions
	}) => (
		<ErrorBoundary>
			<ThemeProvider theme={theme}>
				<ThemeProviderCD theme={theme}>
					<CssBaseline />

					<AlertDlgContext.Provider
						value={{
							securityQuestions,
							onOpenAlertDlg,
							onCloseAlertDlg,
							alertDlgOpen
						}}>
						<div className="App">
							<Route
								path={[
									'/login',
									'/register',
									'/forgotPassword',
									'/recoverpassword'
								]}
								component={LandingHeader}
							/>
							<article className="App-content">
								<Switch>
									<Route
										path="/login"
										render={() => (
											<Login onLogin={onLogin} />
										)}
									/>
									<Route
										path="/register"
										render={() => <Register />}
									/>
									<Route
										path="/forgotPassword"
										render={() => <ForgotPassword />}
									/>
									<Route
										path="/recoverPassword"
										render={() => <RecoverPassword />}
									/>
									<Route
										path="/signout/quit"
										render={() => (
											<Logout
												onLogout={onLogout}
												title="Logging out?"
												message="Are you sure?"
											/>
										)}
									/>
									<Route
										path="/security/f"
										render={props => (
											<DynamicImport
												load={() =>
													import(
														`dynamicForms/components/Security.js`
													)
												}
												{...props}
												editing={true}
												hideCancel={true}>
												{DynamicDefaultCmp}
											</DynamicImport>
										)}
									/>
									<Route
										path="/"
										render={props => <Wrapper {...props} />}
									/>
								</Switch>
							</article>
							<Route
								path={[
									'/login',
									'/register',
									'/forgotPassword',
									'/recoverpassword'
								]}
								component={LandingFooter}
							/>
							<AlertDialog
								fullScreen={alertDlgFullScreen}
								openDlg={alertDlgOpen}
								title={alertDlgTitle}
								message={alertDlgMsg}
								buttonText={alertDlgButtonText || ['OK']}
								onOK={onCloseAlertDlg}
								onCancel={onCancelAlertDlg}
							/>
						</div>
					</AlertDlgContext.Provider>
				</ThemeProviderCD>
			</ThemeProvider>
		</ErrorBoundary>
	)
);

class App extends Component {
	render() {
		return (
			<Router>
				<LoginCtrl />
			</Router>
		);
	}
}

export default App;
