const joi = require('joi');
const R = require('ramda');
const { formatPhone } = require('./utils');

const PHONE_REG_EXP_VALIDATION = /^(\(\d{3}\) |\d{3}-)\d{3}-\d{4}$/;
const PASSWORD_REG_EXP_VALIDATION =
	/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){6,64}$/;
const PHONE_REG_EXP_MASK = [
	'(',
	/[1-9]/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/
];

const ZIP_REG_EXP_MASK = [/[0-9]/, /\d/, /\d/, /\d/, /\d/];

/* address1: "95 ELZEY AVE."
address2: ""
bookNo: "4158364"
city: "ELMONT"
firstName: "Robert"
initDate: "09/30/2006"
lastName: "Sadej"
memStatName: "Journeyman"
owlClass: "A"
phone1: "5163255162"
phone2: "9178543964"
state: "NY"
userName: "info@local79.org"
zipCode: "11003" */

const removePhoneChar = R.replace(/[()-\s]/gi, '');

const ACCOUNT_FORM = [
	{
		name: 'userName',
		label: 'Email',
		type: 't',
		validate: joi
			.string()
			.required()
			.email({ minDomainSegments: 2, tlds: false }),
		mask: ''
	},
	{
		name: 'password',
		label: 'Password',
		type: 'p',
		validate: joi.string().allow('').regex(PASSWORD_REG_EXP_VALIDATION),
		mask: '',
		helperText:
			'Password must consists of 6 or more characters (1 upper case letter, digit and special char).'
	},
	{
		name: 'address1',
		label: 'Address',
		type: 't',
		validate: joi.string().required(),
		mask: ''
	},
	{
		name: 'address2',
		label: 'Apt',
		type: 't',
		validate: joi.string().allow(''),
		mask: ''
	},
	{
		name: 'city',
		label: 'city',
		type: 't',
		validate: joi.string().required(),
		mask: ''
	},
	{
		name: 'state',
		dataSourceName: 'states',
		label: 'State',
		type: 'c',
		validate: joi.string().required().length(2),
		mask: ''
	},
	{
		name: 'zipCode',
		label: 'Zip',
		type: 'm',
		validate: joi.string().required().length(5),
		mask: ZIP_REG_EXP_MASK
	},
	{
		name: 'phone1',
		label: 'Phone 1',
		type: 'm',
		validate: joi.string().regex(PHONE_REG_EXP_VALIDATION),
		mask: PHONE_REG_EXP_MASK,
		preProcess: formatPhone,
		postProcess: removePhoneChar
	},
	{
		name: 'phone2',
		label: 'Phone 2',
		type: 'm',
		validate: joi.string().allow('').regex(PHONE_REG_EXP_VALIDATION),
		mask: PHONE_REG_EXP_MASK,
		preProcess: formatPhone,
		postProcess: removePhoneChar
	}
];

const ACCESS_CODE_REG_EXP_MASK = [
	/[0-9A-Z]/,
	/[0-9A-Z]/,
	/[0-9A-Z]/,
	'-',
	/[0-9A-Z]/,
	/[0-9A-Z]/,
	/[0-9A-Z]/
];
const BOOK_CODE_REG_EXP_MASK = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/
];
const DOB_REG_EXP_MASK = [
	/\d/,
	/\d/,
	'/',
	/\d/,
	/\d/,
	'/',
	/[1-2]/,
	/\d/,
	/\d/,
	/\d/
];

const DOB_VALIDATION = /\d{2}\/\d{2}\/\d{4}/;

const REGISTER_FORM = [
	{
		name: 'email',
		label: 'Email',
		type: 't',
		validate: joi
			.string()
			.required()
			.email({ minDomainSegments: 2, tlds: false }),
		mask: ''
	},
	{
		name: 'password',
		label: 'Password',
		type: 'p',
		validate: joi.string().allow('').regex(PASSWORD_REG_EXP_VALIDATION),
		mask: '',
		helperText:
			'Password must consists of 6 or more characters (1 upper case letter, digit and special char).'
	},
	{
		name: 'accessCode',
		label: 'AccessCode',
		type: 'm',
		validate: joi.string().length(7).required(),
		mask: ACCESS_CODE_REG_EXP_MASK
	},
	{
		name: 'bookNo',
		label: 'Book Number',
		type: 'm',
		validate: joi.string().max(10),
		mask: BOOK_CODE_REG_EXP_MASK
	},
	{
		name: 'firstName',
		label: 'First Name',
		type: 't',
		validate: joi.string().required().max(30),
		mask: ''
	},
	{
		name: 'lastName',
		label: 'Last Name',
		type: 't',
		validate: joi.string().required().max(50),
		mask: ''
	},
	{
		name: 'dob',
		label: 'Date of Birth (mm/dd/yyyy)',
		type: 'm',
		validate: joi.string().regex(DOB_VALIDATION),
		mask: DOB_REG_EXP_MASK
	}
];

const FORGOT_PWD_FORM = [
	{
		name: 'email',
		label: 'Email',
		type: 't',
		validate: joi
			.string()
			.required()
			.email({ minDomainSegments: 2, tlds: false }),
		mask: ''
	},
	{
		name: 'bookNo',
		label: 'Book Number',
		type: 'm',
		validate: joi.string().max(10),
		mask: BOOK_CODE_REG_EXP_MASK
	}
];

const SECURITY_Q_FORM = [
	{
		name: 'email',
		label: 'Email',
		disabled: true,
		type: 't',
		validate: joi
			.string()
			.required()
			.email({ minDomainSegments: 2, tlds: false }),
		mask: ''
	},
	{
		name: 'bookNo',
		label: 'Book Number',
		disabled: true,
		type: 'm',
		validate: joi.string().max(10),
		mask: BOOK_CODE_REG_EXP_MASK
	},
	{
		name: 'answer1',
		label: 'Answer 1',
		type: 't',
		validate: joi.string().required(),
		shrink: false,
		mask: ''
	},
	{
		name: 'answer2',
		label: 'Answer 2',
		type: 't',
		validate: joi.string().required(),
		shrink: false
	},
	{
		name: 'password',
		label: 'Password',
		type: 'p',
		validate: joi.string().required().regex(PASSWORD_REG_EXP_VALIDATION),
		mask: '',
		shrink: false,
		helperText:
			'Password must consists of 6 or more characters (1 upper case letter, digit and special char).'
	}
];

const SET_SECURITY_Q_FORM = [
	{
		name: 'Question_One',
		label: 'Question 1',
		type: 'c',
		validate: joi.number().required(),
		dataSourceName: 'questions1',
		mask: '',
		shrink: false
	},
	{
		name: 'Answer_One',
		label: 'Answer 1',
		type: 't',
		validate: joi.string(),
		mask: '',
		shrink: false
	},
	{
		name: 'Question_Two',
		label: 'Question 2',
		type: 'c',
		validate: joi.number().required(),
		dataSourceName: 'questions2',
		mask: '',
		shrink: false
	},
	{
		name: 'Answer_Two',
		label: 'Answer 2',
		type: 't',
		validate: joi.string(),
		mask: '',
		shrink: false
	}
];

module.exports = {
	ACCOUNT_FORM,
	REGISTER_FORM,
	FORGOT_PWD_FORM,
	SECURITY_Q_FORM,
	SET_SECURITY_Q_FORM
};
