import { postJSONPS } from './lib/utils/fetch';
import { composePS } from '@cd/pipe';

const postGetStaticInfo = info =>
	composePS(postJSONPS)('GET', `/static/${info}`);

const postGetServerVar = info =>
	composePS(postJSONPS)('GET', `/config/var/${info}`);

const postGetAppVersion = () =>
	composePS(postJSONPS)('GET', `/config/get/app/version`, {});

export { postGetServerVar, postGetStaticInfo, postGetAppVersion };
