const { composePS } = require('@cd/pipe');
const R = require('ramda');
const { Left, Right, fnToSafeFn } = require('./utils');

const PROXY_SERVER = '';

const jsonPS = o => o.json();
const bufferPS = o => o.buffer();

const isOkPS = o =>
	o.ok ? Promise.resolve(Right(o)) : Promise.resolve(Left(o.statusText));

const fetchSafe = R.curryN(2, fnToSafeFn(fetch));

// fetchPS :: string -> {} -> Promise(Either)
const fetchPS = R.curryN(2, composePS(jsonPS, isOkPS, fetchSafe));

// fetchPDFPS :: string -> {} -> Promise(Either)
const fetchPDFPS = R.curry((url, options) =>
	composePS(
		res => {
			const contentType = res.headers.get('content-type');
			return contentType && contentType.indexOf('application/json') !== -1
				? jsonPS(res)
				: bufferPS(res);
		},
		isOkPS,
		(url, options) => fetchSafe(`${PROXY_SERVER}${url}`, options)
		// Workaround. When fetchSafe is used in composePS chain the windows reference is lost!
	)(url, options)
);

/** postJSONPS :: string -> {} -> string -> Promise */
const postJSONPS = R.curry((method, url, options = {}) =>
	R.compose(
		fetchPS(`${PROXY_SERVER}/api${url}`),
		R.mergeDeepRight({
			method,
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'same-origin'
		})
	)(options)
);

module.exports = {
	fetchPDFPS,
	fetchPS,
	postJSONPS
};
