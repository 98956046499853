import React from 'react';
import * as R from 'ramda';
import { composePS } from '@cd/pipe';
import { hot } from 'react-hot-loader';
import { withRouter } from 'react-router';
import { withStateCreators } from '../../lib/utils/hoc';
import { withHandlers, compose, lifecycle } from 'recompose';
import styles from '../css/Register.module.scss';
import { postJSONPS } from '../../lib/utils/fetch';
import { either } from '../../lib/utils/utils';
import Form from '../../dynamic/components/form/components/Form';

import { FORGOT_PWD_FORM } from '../../lib/utils/formFields';
import { withForm } from '../../dynamic/components/form/components/formUtils';
import withAlert from './withAlert';

const postGetForgotPasswordInfo = () =>
	composePS(postJSONPS)('POST', `/getForgotPasswordInfo`, {
		body: JSON.stringify({})
	});

const postGetSecurityQ = data =>
	composePS(postJSONPS)('POST', `/getSecurityQuestions`, {
		body: JSON.stringify({ data })
	});

const enhance = compose(
	withRouter,
	withAlert,
	withForm({ joiValidationSchema: FORGOT_PWD_FORM }),
	withStateCreators(
		{
			updateStates: () => states => ({ states }),
			updateAll: () => all => all
		},
		() => ({
			states: []
		})
	),
	lifecycle({
		componentDidMount() {
			const { preProcessData, updateInitData } = this.props;
			composePS(
				updateInitData,
				preProcessData,
				postGetForgotPasswordInfo
			)();
		}
	}),
	withHandlers({
		onUpdateAccount: () => () => {},
		onBackToLogin:
			({ history }) =>
			() => {
				history.push('/login');
			},
		onSave:
			({ data, onSave, onOpenAlertDlg, history }) =>
			() => {
				//onOpenAlertDlg();
				postGetSecurityQ(data).then(
					either(
						e =>
							onOpenAlertDlg({
								message: R.is(String, e)
									? e
									: JSON.stringify(e),
								title: 'Error'
							}),
						secQuestionData => {
							onSave();
							const { email, bookNo } = data;
							history.push('/recoverPassword', {
								data: secQuestionData,
								email,
								bookNo
							});
						}
					)
				);
			}
	})
);

const ForgotPassword = enhance(
	({
		validation = {},
		data,
		onUpdateData,
		showPassword,
		onShowPassword,
		onSave,
		onBackToLogin,
		editing,
		touched
	}) => (
		<div className={styles.wrap} style={{ marginTop: '128px' }}>
			<Form
				alwaysTouched
				formTitle="RECOVER PASSWORD"
				formFields={FORGOT_PWD_FORM}
				data={data}
				onUpdateData={onUpdateData}
				showPassword={showPassword}
				onShowPassword={onShowPassword}
				onSave={onSave}
				onCancel={onBackToLogin}
				validation={validation}
				editing={true}
				touched={touched}
				buttonLabels={['GET SECURITY QUESTIONS', 'LOGIN']}
				buttonIcons={['save', 'keyboard_backspace']}
			/>
		</div>
	)
);

export default hot(module)(ForgotPassword);
