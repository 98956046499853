import React from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

import IndexAppBar from './index/components/IndexAppBar';
import { withHandlers, compose, lifecycle } from 'recompose';
import styles from './Wrapper.module.scss';
import ResponsiveDrawer from './index/components/Drawer';
import { either } from './lib/utils/utils';
import { postJSONPS } from './lib/utils/fetch';
import { composePS } from '@cd/pipe';
import Fallback from './dynamic/components/Fallback';
import { DynamicImport, DynamicDefaultCmp } from './DynamicImport';
import { withStateCreators } from './lib/utils/hoc';
import { QueryClient, QueryClientProvider } from '@cd/react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

/** getNav ::  Promise(Either) */
const getNav = () =>
	composePS(
		/* R.reduce((acc, [, val]) => R.append(val, acc), []),
		R.toPairs,
		R.merge({ dupa: 'jas' }), */
		postJSONPS
	)('POST', `/nav/list`, {
		body: JSON.stringify({})
	});

const enhance = compose(
	withRouter,
	withStateCreators(
		{
			updateMobileOpen: () => mobileOpen => ({ mobileOpen }),
			updateSearchValue: () => searchValue => ({ searchValue })
		},
		() => ({
			mobileOpen: false,

			searchValue: ''
		})
	),
	withHandlers({
		onChangeURL:
			({ updateMobileOpen, history }) =>
			href => {
				updateMobileOpen(false);
				history.push(href);
			},
		onSearchValue:
			({ updateSearchValue }) =>
			e => {
				updateSearchValue(
					R.replace(/[\\/[\](){}^$!*.?;:<>]*/gi, '', e.target.value)
				);
			},
		onOpenNavDrawer:
			({ updateMobileOpen }) =>
			() => {
				updateMobileOpen(true);
			}
	}),
	lifecycle({
		componentDidMount() {
			getNav().then(
				either(console.log, navList => {
					this.setState({ navList });
				})
			);
		}
	})
);

const Wrapper = enhance(
	({
		navList = [],
		onChangeURL,
		onOpenNavDrawer,
		mobileOpen,
		searchValue,
		onSearchValue
	}) => (
		<QueryClientProvider client={queryClient}>
			<div className={styles.wrapper}>
				<ResponsiveDrawer
					navList={navList}
					onChangeURL={onChangeURL}
					mobileOpen={mobileOpen}
				/>
				<div className={styles.appArea}>
					<IndexAppBar
						navList={navList}
						onOpenNavDrawer={onOpenNavDrawer}
						searchValue={searchValue}
						onSearchValue={onSearchValue}
					/>
					<Switch>
						{navList.map(
							({ src, href }) =>
								src ? (
									<Route
										key={href}
										path={href}
										render={props => (
											<DynamicImport
												load={() =>
													import(
														`dynamicForms/${src}`
													)
												}
												navList={navList}
												searchValue={searchValue}
												{...props}>
												{DynamicDefaultCmp}
											</DynamicImport>
										)}
									/>
								) : (
									<Fallback key={href} />
								),

							navList
						)}
					</Switch>
				</div>
			</div>
		</QueryClientProvider>
	)
);

export default Wrapper;
