import { hot } from 'react-hot-loader/root';
import React from 'react';
import * as R from 'ramda';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MaskedInput from 'react-text-mask';
import TextField from '@mui/material/TextField';
//import styles from './css/TextFieldCD.scss';

const TextMaskCustom = React.forwardRef(({ mask, ...other }, inputRef) => {
	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={mask}
			placeholderChar={'\u2000'}
			showMask
		/>
	);
});

const TextFieldCD = ({
	name,
	label,
	classes,
	type,
	disabled,
	validation,
	mask,
	inputProps,
	shrink,
	data,
	onUpdateData,
	helperText,
	showPassword,
	onShowPassword
}) => {
	return (
		<TextField
			key={name}
			disabled={disabled}
			id={name}
			error={validation[name]}
			name={name}
			label={label}
			className={classes.textField}
			value={data[name]}
			onChange={onUpdateData}
			margin="normal"
			variant="outlined"
			InputLabelProps={
				shrink === false
					? {}
					: {
							shrink: true
					  }
			}
			helperText={helperText}
			type={type === 'p' && !showPassword ? 'password' : 'text'}
			InputProps={R.mergeRight(
				{
					endAdornment:
						type === 'p' ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="Toggle password visibility"
									onClick={onShowPassword}
									size="large">
									{showPassword ? (
										<Visibility />
									) : (
										<VisibilityOff />
									)}
								</IconButton>
							</InputAdornment>
						) : null
				},
				type === 'm'
					? {
							inputComponent: TextMaskCustom,
							inputProps: {
								mask,
								guide: false
							}
					  }
					: {}
			)}
			{...inputProps}
		/>
	);
};
export default hot(TextFieldCD);
